import * as yup from 'yup';
import { ACTIVE_ROLES } from '../../../Lib/CONSTANTS';
import {
  validateSpecialCharacterInString,
  validateStringNotToStartWithNumber,
  validateStringNotToStartWithSpecialCharacter,
  validateUsername
} from '../../Form';

function isValidString (s) {
  return /^[0-9a-zA-Z']+$/.test(s);
}

yup.addMethod(yup.string, 'alphaNumeric', function (errorMessage) {
  return this.test('alpha-numeric', errorMessage, function (value) {
    const { path, createError } = this;

    return isValidString(value)
      ? createError({ path, message: errorMessage })
      : true;
  });
});

yup.addMethod(yup.string, 'noSpecialCharacter', function (errorMessage) {
  return this.test('no-special-character', errorMessage, function (value) {
    const { path, createError } = this;

    return validateSpecialCharacterInString(value)
      ? createError({ path, message: errorMessage })
      : true;
  });
});

yup.addMethod(yup.string, 'validateUsername', function (errorMessage) {
  return this.test('validate-username', errorMessage, function (value) {
    const { path, createError } = this;

    return validateUsername(value)
      ? createError({ path, message: errorMessage })
      : true;
  });
});

yup.addMethod(yup.string, 'noNumberAtStart', function (errorMessage) {
  return this.test('no-number-at-start', errorMessage, function (value) {
    const { path, createError } = this;

    return validateStringNotToStartWithNumber(value)
      ? createError({ path, message: errorMessage })
      : true;
  });
});

yup.addMethod(yup.string, 'noSpecialCharacterAtStart', function (errorMessage) {
  return this.test(
    'no-special-character-at-start',
    errorMessage,
    function (value) {
      const { path, createError } = this;

      return validateStringNotToStartWithSpecialCharacter(value)
        ? createError({ path, message: errorMessage })
        : true;
    }
  );
});

export const getUserSchema = ({ isEditingUser }) => {
  const isCreatingUser = !isEditingUser;
  return yup.object().shape({
    // TODO: Fix optional validations and added special validations from old user form
    title: yup
      .string()
      .nullable()
      .when('role', {
        is: (role) => role !== ACTIVE_ROLES.LEARNER,
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.notRequired()
      })
      .label('Title/Rank'),
    firstName: yup
      .string()
      .label('First Name')
      .noSpecialCharacter(
        'Field can only contain following special characters: dot(.) and hyphen(-)'
      )
      .noNumberAtStart('First name cannot start with a number.')
      .noSpecialCharacterAtStart(
        'First name cannot start with special character.'
      )
      .max(49)
      .required('Please enter first name.'),
    lastName: yup
      .string()
      .label('Last Name')
      .noSpecialCharacter(
        'Field can only contain following special characters: dot(.) and hyphen(-)'
      )
      .noNumberAtStart('Last name cannot start with a number.')
      .noSpecialCharacterAtStart(
        'Last name cannot start with special character.'
      )
      .max(49)
      .required('Please enter last name.'),
    username: yup
      .string()
      .when({
        is: () => isCreatingUser,
        then: (schema) =>
          schema
            .min(4)
            .max(49)
            .validateUsername(
              'Field can only contain following special characters: hyphen(-) and underscore(_)'
            )
            .required('Please enter username.'),
        otherwise: (schema) => schema.notRequired()
      })
      .label('Username'),
    showFirstNameToLearners: yup.boolean(),
    dob: yup
      .string()
      .when('role', {
        is: (role) => window.nucleosConfig.showDOB && role === ACTIVE_ROLES.LEARNER,
        then: (schema) =>
          schema.required('Please enter Date of Birth.'),
        otherwise: (schema) => schema.notRequired()
      })
      .label('Date of Birth'),
    email: yup
      .string()
      .when((emailValue, schema) => {
        return schema.when('role', {
          is: (role) =>
            role !== ACTIVE_ROLES.LEARNER ||
            (role === ACTIVE_ROLES.LEARNER && emailValue[0]),
          then: (schema) =>
            schema
              .email('Please enter valid email address.')
              .required('Please enter email address.'),
          otherwise: (schema) => schema.optional()
        });
      })
      .label('Email'),
    password: yup
      .string()
      .when({
        is: (password) => !(!password && isEditingUser),
        then: (schema) =>
          schema.min(6).max(50).required('Please enter password.'),
        otherwise: (schema) => schema.notRequired()
      })
      .label('Password'),
    confirmPassword: yup
      .string()
      .when({
        is: () => isCreatingUser,
        then: (schema) =>
          schema
            .oneOf([yup.ref('password')], 'Passwords do not match.')
            .required('Please enter password.'),
        otherwise: (schema) => schema.notRequired()
      })
      .label('Confirm password'),
    role: yup.string().label('Role').required('Please select role.'),
    grievanceRole: yup
      .string()
      .label('Grievance role')
      .optional('Please select grievance role.'),
    facilities: yup
      .array(yup.string())
      .when(['role', 'grievanceRole'], {
        is: (role, grievanceRole) => {
          return (
            role === ACTIVE_ROLES.LEARNER ||
            (role !== ACTIVE_ROLES.LEARNER && grievanceRole)
          );
        },
        then: (schema) =>
          schema
            .min(1, 'Please select at least one facility.')
            .required('Please select facility(s).'),
        otherwise: (schema) => schema.optional()
      })
      .label('Facility(s)')
  });
};
