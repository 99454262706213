import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Typography,
  SearchInput,
  Table,
  DateRangePicker,
  MultiSelectDropDown,
  Chip
} from '@nucleos/core-ui';
import moment from 'moment';
import HTMLReactParser from 'html-react-parser';

import Data from '../../Middleware/Data';
import { QueryKeys } from '../../Lib/query-keys';
import { useFiltersManager } from '../../hooks/useFilters';
import { LoadingAndErrorHandler } from '../../Components/Shared/LoadingErrorHandler';
import { GenericNoDataFound } from '../../Components/ErrorStates/GenericNoDataFound';
import {
  formatDateTime,
  tableFilterLexicographicalSorter
} from '../../Lib/util';
import { useTableSorting } from '../../hooks/useTableSorting';
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery';

const defaultSorting = {
  column: 'CREATED_AT',
  sortOrder: 'DESC'
};

const FilterKeys = {
  eventType: 'eventType',
  event: 'event',
  adminName: 'adminName',
  createdOnStartDate: 'createdOnStartDate',
  createdOnEndDate: 'createdOnEndDate'
};

const ColumnKeys = {
  ADMIN_NAME: 'ADMIN_NAME',
  CREATED_AT: 'CREATED_AT'
};

const ApplicationAuditHistory = ({ applicationUid }) => {
  const filterManager = useFiltersManager({
    defaultFilter: [],
    onFilterChange: () => setPage(1),
    urlKey: 'filters'
  });

  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'app-audit-logs'
    });
  const [userSearch, setUserSearch] = useState('');

  const startDate = filterManager.getValue(FilterKeys.createdOnStartDate);
  const endDate = filterManager.getValue(FilterKeys.createdOnEndDate);

  const eventTypesQuery = useQuery(
    QueryKeys.ApplicationAuditHistory.listing(),
    () => Data.getApplicationAuditLogsEventTypes(),
    {
      placeholderData: {}
    }
  );

  const eventTypeOptions = useMemo(() => {
    return (
      Object.entries(eventTypesQuery.data || {}).map(([value, title]) => ({
        value,
        title
      })) || []
    );
  }, [eventTypesQuery.data]);

  const usersSearchQuery = usePaginatedQuery(
    ['USERS_SEARCH', userSearch],
    ({ pageParam = {} }) => {
      return Data.getUsers({
        query: userSearch,
        page: pageParam.page ? pageParam.page - 1 : 0,
        body: { roleIds: [1, 6, 9] },
        limit: 10
      });
    },
    {
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((r) => ({
            title: `${r.firstName} ${r.lastName}`,
            value: r.uid
          }))
        };
      },
      listKeyName: 'users',
      placeholderData: { data: { pages: [] } }
    }
  );

  const requestData = {
    applicationUid,
    offset: (page - 1) * recordsPerPage,
    orderBy: columnSorting.sortOrder !== 'NONE' ? columnSorting.column : '',
    order: columnSorting.sortOrder !== 'NONE' ? columnSorting.sortOrder : '',
    q: JSON.stringify({
      eventTypes: filterManager.getValues(FilterKeys.eventType) || '',
      event: filterManager.getValue(FilterKeys.event) || '',
      admins: filterManager.getValues(FilterKeys.adminName) || '',
      dateStart: startDate || '',
      dateEnd: endDate || ''
    }),
    limit: recordsPerPage
  };

  const auditLogsQuery = useQuery(
    QueryKeys.ApplicationAuditHistory.listing(requestData),
    () => Data.getApplicationAuditLogs(requestData),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: { rows: [], count: 0 }
    }
  );

  const getEventType = (type) => {
    return (eventTypesQuery.data || {})[type];
  };

  const renderMultiSelectFilter = (
    dropdownId,
    index,
    columnList,
    filter,
    label,
    options,
    type,
    testId,
    searchable = false,
    searchValue = '',
    onSearchChange = () => null,
    isSearchLoading = false,
    isNextPageLoading = false,
    hasNextOptions = false,
    fetchNextOptionsPage = () => null
  ) => {
    const transformValue = (v) => ({ title: v.meta.label, value: v.value });

    return (
      <MultiSelectDropDown
        dropdownId={dropdownId}
        testId={testId}
        DropdownContainerProps={{
          direction: index === columnList.length - 1 ? 'left' : 'right'
        }}
        disableHelperText
        value={filterManager.getValues(FilterKeys[filter], { transformValue })}
        onChange={(values) => {
          filterManager.onBulkFilterApply(
            FilterKeys[filter],
            values.map((v) => ({
              value: v.value,
              meta: { label: v.title }
            }))
          );
        }}
        label={label}
        getLabel={(selected) =>
          `Selected ${type}${selected.length > 1 ? 's' : ''} (${selected.length
          })`
        }
        fullWidth
        options={options}
        searchable={searchable}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        isSearchLoading={isSearchLoading}
        isNextPageLoading={isNextPageLoading}
        hasNextOptions={hasNextOptions}
        fetchNextOptionsPage={fetchNextOptionsPage}
      />
    );
  };

  const columns = [
    {
      title: 'Event Type',
      enableSort: false,
      render: (item) => <Typography>{getEventType(item.eventType)}</Typography>,
      renderFilter: (_, index, columnList) =>
        renderMultiSelectFilter(
          'app-audit-history-event-type-select',
          index,
          columnList,
          'eventType',
          'All Types',
          eventTypeOptions,
          'Event',
          'filter-event-type'
        )
    },
    {
      title: 'Event',
      enableSort: false,
      render: (item) => (
        HTMLReactParser(item.event)
      ),
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Search"
          style={{ width: '80ch' }}
          value={filterManager.getValue(FilterKeys.event) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.event) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.event }); }
            filterManager.onFilterApply({
              key: FilterKeys.event,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'By User',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.ADMIN_NAME),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.ADMIN_NAME, sortOrder }),
      render: (item) => <Typography>{item.adminName}</Typography>,
      renderFilter: (_, index, columnList) => {
        const userOptions = ((usersSearchQuery.data || {}).pages || []).sort(
          tableFilterLexicographicalSorter
        );
        return renderMultiSelectFilter(
          'app-audit-history-users-select',
          index,
          columnList,
          'adminName',
          'All Users',
          userOptions,
          'User',
          'filter-user',
          true,
          userSearch,
          setUserSearch,
          usersSearchQuery.isFetching && !usersSearchQuery.isFetched,
          usersSearchQuery.isFetching,
          usersSearchQuery.hasNextPage,
          usersSearchQuery.fetchNextPage
        );
      }
    },
    {
      title: 'Date & Time',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.CREATED_AT),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.CREATED_AT, sortOrder }),
      render: (item) => (
        <Typography>{formatDateTime(item.createdAt)}</Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <DateRangePicker
          testId="filter-created-on"
          DropdownContainerProps={{
            direction: 'left'
          }}
          disableHelperText
          fullWidth
          placeholder="Date Range"
          maxDate={new Date()}
          value={{
            startDate: startDate ? new Date(startDate) : null,
            endDate: endDate ? new Date(endDate) : null
          }}
          onChange={({ startDate, endDate }) => {
            filterManager.onFilterApply({
              key: FilterKeys.createdOnStartDate,
              value: moment(startDate).format('YYYY-MM-DD'),
              meta: {
                label: `${moment(startDate).format('MM/DD/YYYY')} - ${moment(
                  endDate
                ).format('MM/DD/YYYY')}`
              }
            });
            filterManager.onFilterApply({
              key: FilterKeys.createdOnEndDate,
              value: moment(endDate).format('YYYY-MM-DD')
            });
          }}
        />
      )
    }
  ];

  const appliedFilterLabels = filterManager.filters
    .filter((filter) => ![FilterKeys.createdOnEndDate].includes(filter.key))
    .map((filter) => (
      <Chip
        key={filter.key}
        rounded="full"
        className="mb-2 mr-2"
        style={{ padding: '6px 8px' }}
        label={filter.meta ? filter.meta.label : filter.value}
        onDelete={() => onFilterDelete(filter)}
      />
    ));

  const onFilterDelete = (appliedFilter) => {
    const isDateRangeFilter = [
      FilterKeys.createdOnStartDate,
      FilterKeys.createdOnEndDate
    ].includes(appliedFilter.key);

    if (isDateRangeFilter) {
      filterManager.onFilterRemove({
        key: FilterKeys.createdOnStartDate
      });
      filterManager.onFilterRemove({
        key: FilterKeys.createdOnEndDate
      });
      return;
    }

    filterManager.onFilterRemove({
      key: appliedFilter.key,
      value: appliedFilter.value
    });
  };

  return (
    <div className="nucleos-core">
      <div className="flex items-center flex-wrap">
        {appliedFilterLabels.length
          ? (
            <Typography className="mb-2 mr-2">Applied Filters:</Typography>
          )
          : null}
        {appliedFilterLabels}
      </div>
      <LoadingAndErrorHandler
        isLoading={auditLogsQuery.isLoading}
        isSuccess={auditLogsQuery.isSuccess}
        isError={auditLogsQuery.isError}
      >
        <Table
          useFixedHeight
          columns={columns}
          noDataMessage={<GenericNoDataFound className="mb-5" />}
          loading={auditLogsQuery.isFetching}
          rowsData={auditLogsQuery.data.rows ? auditLogsQuery.data.rows : []}
          pagination
          totalRecords={
            auditLogsQuery.data.count ? auditLogsQuery.data.count : 0
          }
          recordsPerPage={recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
        />
      </LoadingAndErrorHandler>
    </div>
  );
};

export default ApplicationAuditHistory;
