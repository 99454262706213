import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Input, Button } from '@mui/material';
import FlexTileGrid from '../../Components/Shared/TileGrid/TileGrid';
import { UserSearch } from '../../Components/Widgets/UserSearch';

import Data from '../../Middleware/Data';

export const ApplicationLicense = ({ ApplicationUid }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUpdateUser, setCurrentUpdateUser] = useState({});
  const [description, setDescription] = useState('');
  const [key, setKey] = useState('');
  const [secret, setSecret] = useState('');
  const [licenses, setLicenses] = useState([]);
  const [clicked, setClicked] = useState(-1);

  useEffect(() => {
    const stupidReactSyntax = async () => {
      const { licenses } = await Data.getLicenses(ApplicationUid);
      setLicenses(licenses);
    };
    stupidReactSyntax();
  }, []);

  const handleSelectUser = (user) => {
    setCurrentUser(user);
  };
  const handleUpdateSelectUser = user => {
    setCurrentUpdateUser(user);
  };

  const username = currentUser && (currentUser.username);
  const submitForm = async () => {
    if (!currentUser || !key || !secret) { return 'error'; }
    const { uid: UserUid } = currentUser;
    const result = await Data.addLicense(ApplicationUid, UserUid, key, secret, description);
    if (result.success) {
      setLicenses([...licenses, { ApplicationUid, username: currentUser.username, key, secret, description }]);
    }
  };
  return (
    <div className='app-detail__info-panel'>
      {currentUser
        ? (
          <p>
            {username} is selected
          </p>
        )
        : (
          <p>
          Enter name of user you want to sign-up
          </p>
        )}
      <div style={{ marginTop: '20px' }}>
        <FlexTileGrid
          gridColTiers={{ xs: 12 }}
          tileStyle={{ padding: 0 }}
          className='usage-history__table-container'
        >
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell>User</TableCell>
                <TableCell align='left'>Description</TableCell>
                <TableCell align='left'>Course Reference Code</TableCell>
                <TableCell align='left'>Access Key</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {licenses.length > 0 && (licenses.map(({ id, key, secret, description, username, User }) => {
                return <TableRow key={id} style={id === clicked ? { backgroundColor: '#f8f8fb' } : {}}>
                  <TableCell
                    align='left'
                    onClick={() => {
                      if (id !== clicked) { setCurrentUpdateUser({}); }
                      setClicked(id);
                    }}
                  >
                    {
                      clicked === id
                        ? currentUpdateUser.username
                          ? <a className='link' href='/' onClick={(e) => {
                            e.preventDefault();
                            setCurrentUpdateUser({});
                          }}> {currentUpdateUser.username} </a>
                          : <UserSearch materialUI onSelectUser={handleUpdateSelectUser} />
                        : <a className='link' href='/' onClick={(e) => { e.preventDefault(); }}>{username || User.username}</a>
                    }
                  </TableCell>
                  <TableCell align='left'>{description}</TableCell>
                  <TableCell align='left'>{key}</TableCell>
                  <TableCell align='left'>{secret}</TableCell>
                  {clicked === id && <TableCell align='left'><Button disabled={!currentUpdateUser.username} onClick={async () => {
                    const result = await Data.updateLicense(id, currentUpdateUser.uid);
                    if (result.success) {
                      licenses.find(license => license.id === id).username = currentUpdateUser.username;
                    }
                    setCurrentUpdateUser({});
                    setClicked(-1);
                  }}>update</Button></TableCell>}
                </TableRow>;
              })
              )}
              <TableRow>
                <TableCell align='left'> {username
                  ? <a href="/" onClick={(e) => {
                    e.preventDefault();
                    setCurrentUser(null);
                  }} className='link'>{ username }</a>
                  : <UserSearch materialUI onSelectUser={handleSelectUser} />} </TableCell>
                <TableCell align='left'><Input onChange={e => setDescription(e.target.value)} value={description} placeholder='description' /></TableCell>
                <TableCell align='left'><Input onChange={e => setKey(e.target.value)} value={key} placeholder='course code' /></TableCell>
                <TableCell align='left'><Input onChange={e => setSecret(e.target.value)} value={secret} placeholder='access key' /></TableCell>
                <TableCell align='left'><Button onClick={submitForm}>add</Button></TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </FlexTileGrid>
      </div>
    </div>
  );
};
