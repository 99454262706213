class QueryKeysGenerator {
  identifier;

  constructor (identifier) {
    this.identifier = identifier;
  }

  all () {
    return [this.identifier];
  }

  listings () {
    return [...this.all(), 'LISTING'];
  }

  listing (filters) {
    if (filters && Object.values(filters).some((v) => v !== undefined)) {
      return [...this.listings(), filters];
    }

    return this.listings();
  }

  items () {
    return [...this.all(), 'ITEM'];
  }

  item (id) {
    return [...this.items(), id];
  }
}

export const QueryKeys = Object.freeze({
  CurrentUser: 'CurrentUser',
  UserTitles: 'UserTitles',
  Categories: new QueryKeysGenerator('Categories'),
  LearnerCategories: new QueryKeysGenerator('LearnerCategories'),
  Users: new QueryKeysGenerator('Users'),
  Roles: new QueryKeysGenerator('Roles'),
  Groups: new QueryKeysGenerator('Groups'),
  GrievanceResponseTemplates: new QueryKeysGenerator('GrievanceResponseTemplates'),
  Institutions: new QueryKeysGenerator('Institutions'),
  Applications: new QueryKeysGenerator('Applications'),
  CourseStatusReport: new QueryKeysGenerator('CourseStatusReport'),
  GroupLearningRecords: new QueryKeysGenerator('GroupLearningRecords'),
  LearnerSessionLookup: new QueryKeysGenerator('LearnerSessionLookup'),
  FaceCaptureLogs: new QueryKeysGenerator('FaceCaptureLogs'),
  ApplicationAuditHistory: new QueryKeysGenerator('ApplicationAuditHistory'),
  Notifications: new QueryKeysGenerator('Notifications'),
  FlaggedEvents: new QueryKeysGenerator('FlaggedEvents'),
  FlaggedActivity: new QueryKeysGenerator('FlaggedActivity')
});
