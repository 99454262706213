import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import { Button, Switch, Typography, Accordion, AccordionSummary, AccordionDetails, Divider, Box, Tabs, Tab } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { observer } from 'mobx-react';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import ApplicationHeader from './ApplicationHeader';
import ApplicationInformation from './ApplicationInformation';
import ApplicationUsers from './ApplicationUsers';
import ApplicationCourses from './ApplicationCourses';
import './Application.scss';
import AuthenticationStore from '../../Stores/Authentication';
import DataStore from '../../Stores/DataStore';
import { ApplicationEnroll } from './ApplicationEnroll';
import { ApplicationLicense } from './ApplicationLicense';
import AppAvailabilityAndLimits from './AppAvailabilityAndLimits/AppAvailabilityAndLimits';
import FlexTileGrid from '../../Components/Shared/TileGrid/TileGrid';
import UserPasswords from './UserPasswords';
import { useMutation } from 'react-query';
import Data from '../../Middleware/Data';
import useUserPermissions from '../../hooks/useUserPermissions';
import ApplicationAuditHistory from './ApplicationAuditHistory';
import HeaderSubPanel from '../../Components/HeaderSubPanel';
import { COURSE_CONTENT_APPLICATION_PATH, COURSE_CONTENT_LIBRARY_PATH } from '../../constants/paths';

const TAB_KEYS = {
  Information: 'information',
  UserAccess: 'user-access',
  AppAvailabilityAndLimits: 'app-availability',
  UserPasswords: 'user-passwords',
  AuditHistory: 'audit-history',
  // Obsolete?
  Enroll: 'enroll',
  AssignLicenses: 'assign-licenses'
};

const breadcrumbLink = (name) => [
  { href: COURSE_CONTENT_LIBRARY_PATH, label: 'Course & Content Library' },
  { href: '#', label: name }
];

const Course = () => {
  const { path, params } = useRouteMatch();
  const { uid: id, tabId } = params;

  const {
    appDetail,
    getAppDetail,
    getAppDetailLoading,
    getUsers,
    setPageTitleDetail,
    appLoading,
    updateApplication,
    updateApplicationLoading,
    isCurrentUserAdmin
  } = DataStore;
  const { userRole } = AuthenticationStore;
  const isSuperAdmin = isCurrentUserAdmin();
  const isLearner = userRole === 'learner';
  const history = useHistory();
  const userPermissions = useUserPermissions();

  const [webLinksCategories, setWebLinksCategories] = useState([]);
  const locationBlockerRef = useRef();
  // const pageReloadBlocker = useCallback(async (event) => {
  //   event.preventDefault();
  //   event.returnValue = "";
  // }, []);
  const hasUnsavedChanges = webLinksCategories.some(
    (category) => category.hasChanged
  );

  appDetail.metadata = appDetail.metadata || {};
  const { metadata, uid, Courses } = appDetail;

  const { enroll, licenses, loginRequired, applicationKey } = metadata;

  const canViewApplicationAuditHistory = userPermissions.canViewAppAuditHistory();

  useEffect(() => {
    DataStore.setAppLoading(false);
  }, []);

  useEffect(() => {
    if (appDetail.weblinks) { setWebLinksCategories(appDetail.weblinks); }
  }, [appDetail.weblinks]);

  const confirmSaveChanges = useCallback(() => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        message: 'You have unsaved changes, Would you like to save them?',
        buttons: [
          {
            label: 'Discard',
            onClick: reject,
            className: ''
          },
          {
            label: 'Save',
            onClick: resolve,
            className: 'dialog-button-color'
          }
        ]
      });
    });
  }, []);

  const onWebLinksSave = useCallback(async () => {
    const changedCategories = webLinksCategories
      .filter((category) => category.hasChanged)
      .map((category) => ({
        uid: category.uid,
        enabled: category.enabled,
        urls: category.urls
          .filter((url) => url.hasChanged)
          .map((url) => ({ uid: url.uid, enabled: url.enabled }))
      }));

    await updateApplication(appDetail.uid, {
      weblinks: changedCategories
    });
    return getAppDetail(appDetail.uid);
  }, [appDetail.uid, getAppDetail, updateApplication, webLinksCategories]);

  const onMoveAway = useCallback(async () => {
    await confirmSaveChanges();
    await onWebLinksSave();
  }, [confirmSaveChanges, onWebLinksSave]);

  useEffect(() => {
    if (hasUnsavedChanges) {
      // window.addEventListener("beforeunload", pageReloadBlocker, true);
      locationBlockerRef.current = history.block((location) => {
        onMoveAway().finally(() => {
          locationBlockerRef.current();
          history.push(location);
        });
        return false;
      });
    } else if (locationBlockerRef.current) {
      // window.removeEventListener("beforeunload", pageReloadBlocker, true);
      locationBlockerRef.current();
    }
  }, [hasUnsavedChanges, history, onMoveAway]);

  useEffect(() => {
    getAppDetail(id);
    if (!isLearner) {
      getUsers();
    }
  }, [isLearner, getAppDetail, id, getUsers]);

  useEffect(() => {
    const { name, displayName } = appDetail;
    if (displayName || name) { setPageTitleDetail(`Course & Content Library > ${displayName || name}`); }

    return () => setPageTitleDetail(null);
  }, [setPageTitleDetail, appDetail]);

  const onWebLinkToggle = ({ linkIndex, categoryIndex }, isEnabled) => {
    const newWebLinksCategories = [...webLinksCategories];
    const newUrls = [...newWebLinksCategories[categoryIndex].urls];
    newUrls[linkIndex].enabled = isEnabled;
    newUrls[linkIndex].hasChanged = true;
    newWebLinksCategories[categoryIndex] = {
      ...newWebLinksCategories[categoryIndex],
      urls: newUrls,
      hasChanged: true
    };
    newWebLinksCategories[categoryIndex].enabled = newWebLinksCategories[
      categoryIndex
    ].urls.some((category) => category.enabled);
    setWebLinksCategories(newWebLinksCategories);
  };

  const onWebLinkCategoryToggle = (categoryIndex, isEnabled) => {
    const newWebLinksCategories = [...webLinksCategories];
    newWebLinksCategories[categoryIndex] = {
      ...newWebLinksCategories[categoryIndex],
      urls: newWebLinksCategories[categoryIndex].urls.map((url) => ({
        ...url,
        enabled: isEnabled,
        hasChanged: true
      })),
      enabled: isEnabled,
      hasChanged: true
    };
    setWebLinksCategories(newWebLinksCategories);
  };

  const renderSwitch = (link, categoryIndex, linkIndex) => (
    <Switch
      checked={link.enabled}
      value={link.enabled}
      onChange={(e) =>
        onWebLinkToggle({ categoryIndex, linkIndex }, e.target.checked)
      }
      color="primary"
    />
  );

  const renderAccordionImageContent = (item, categoryIndex) => (
    <FlexTileGrid
      gridColTiers={{
        xs: 6,
        md: 4,
        lg: 3,
        xl: 2
      }}
      tileStyle={{ padding: '12px' }}
      spacing={3}
    >
      {item.urls.map((link, linkIndex) => (
        <div
          key={link.title}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <a href={link.link} style={{ margin: 'auto', width: appDetail.name === 'Google Workspace' ? '50%' : '75%' }}>
            <img src={link.image} alt={link.title} style={{ width: '100%' }} />
          </a>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 8
            }}
          >
            <a href={link.link}>
              <Typography color="primary" style={{ paddingLeft: 12 }}>
                {link.title}
              </Typography>
            </a>
            {!isLearner ? renderSwitch(link, categoryIndex, linkIndex) : null}
          </div>
        </div>
      ))}
    </FlexTileGrid>
  );

  const renderAccordionTextContent = (item, categoryIndex) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingLeft: 18
      }}
    >
      {item.urls.map((link, linkIndex) => (
        <div
          key={link.title}
          style={{
            padding: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: linkIndex % 2 === 0 ? '#00000008' : '#0000001a'
          }}
        >
          <div>
            <a href={link.link}>
              <Typography color="primary">{link.title}</Typography>
            </a>
          </div>
          {!isLearner ? renderSwitch(link, categoryIndex, linkIndex) : null}
        </div>
      ))}
    </div>
  );

  const vantageSSOLoginMutation = useMutation(Data.vantageSSOLogin);
  const ssoVantageLogin = (userUid) => vantageSSOLoginMutation.mutate(userUid, {
    onSuccess: (data) => {
      if (data && data.ssoLoginLink) {
        window.location.assign(data.ssoLoginLink);
      }
    }
  });

  const tabs = useMemo(() => {
    const tabList = [{
      key: TAB_KEYS.Information,
      label: 'Information'
    }];

    if (!isLearner) {
      tabList.push({
        key: TAB_KEYS.UserAccess,
        label: 'User Access'
      });
      tabList.push({
        key: TAB_KEYS.AppAvailabilityAndLimits,
        label: 'App Availability'
      });

      if (isSuperAdmin && loginRequired) {
        tabList.push({
          key: TAB_KEYS.UserPasswords,
          label: 'User Passwords'
        });
      }

      if (canViewApplicationAuditHistory) {
        tabList.push({
          key: TAB_KEYS.AuditHistory,
          label: 'Audit History'
        });
      }

      if (enroll) {
        tabList.push({
          key: TAB_KEYS.Enroll,
          label: 'Enroll'
        });
      }

      if (licenses) {
        tabList.push({
          key: TAB_KEYS.AssignLicenses,
          label: 'Assign Licenses'
        });
      }
    }

    return tabList;
  }, [isLearner, isSuperAdmin, loginRequired, enroll, licenses, canViewApplicationAuditHistory]);

  const handleTabChange = useCallback((_, value) => {
    history.replace(generatePath(path, {
      uid: id,
      tabId: value
    }));
  }, [history, path, id]);

  useEffect(() => {
    const tabMatch = tabs.some((tab) => tab.key === tabId);

    if (!tabId || !tabMatch) {
      history.replace(generatePath(COURSE_CONTENT_APPLICATION_PATH[1], {
        uid: id,
        tabId: TAB_KEYS.Information
      }));
    }
  }, [history, tabId, id, path, tabs, handleTabChange]);

  const activeTabKey = useMemo(() => tabId, [tabId]);

  if (getAppDetailLoading || appLoading || vantageSSOLoginMutation.isLoading) {
    return <LoadingComponent />;
  } else if (appDetail && appDetail.name === undefined) {
    return <h2>No course data available</h2>;
  } else {
    return (
      <Box>
        <HeaderSubPanel
          links={breadcrumbLink(appDetail.displayName || appDetail.name)}
          sx={{ mb: 1 }}
        />

        <div className="app-detail">
          <ApplicationHeader ssoVantageLogin={ssoVantageLogin} />

          <Divider />

          <Box>
            <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTabKey} onChange={handleTabChange}>
                {
                  tabs.map((tab) => (
                    <Tab key={tab.key} value={tab.key} label={tab.label} />
                  ))
                }
              </Tabs>
            </Box>

            <Box sx={{ mt: 1.5 }}>
              {activeTabKey === TAB_KEYS.Information && (
                <ApplicationInformation />
              )}
              {activeTabKey === TAB_KEYS.UserAccess && (
                <ApplicationUsers />
              )}
              {activeTabKey === TAB_KEYS.AppAvailabilityAndLimits && (
                <AppAvailabilityAndLimits appUid={uid} />
              )}
              {activeTabKey === TAB_KEYS.UserPasswords && (
                <UserPasswords
                  applicationName={appDetail.name}
                  applicationUid={appDetail.uid}
                  applicationKey={applicationKey || appDetail.name}
                  userPasswordMetadata={metadata && metadata.userPasswordMetadata ? metadata.userPasswordMetadata : []}
                />
              )}
              {activeTabKey === TAB_KEYS.AuditHistory && (
                <ApplicationAuditHistory applicationUid={uid} />
              )}
              {activeTabKey === TAB_KEYS.Enroll && (
                <ApplicationEnroll appUid={uid} appCourses={Courses} />
              )}
              {activeTabKey === TAB_KEYS.AssignLicenses && (
                <ApplicationLicense ApplicationUid={uid} />
              )}
            </Box>

            <section>
              {Courses.length > 0 && activeTabKey === TAB_KEYS.Information && <ApplicationCourses />}

              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                {
                  hasUnsavedChanges && !isLearner && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ margin: '20px 0', padding: '6px 12px' }}
                      onClick={() => onWebLinksSave()}
                      disabled={updateApplicationLoading}
                    >
                      Save Changes
                    </Button>
                  )
                }
              </div>

              {
                webLinksCategories.length && activeTabKey === TAB_KEYS.Information
                  ? webLinksCategories.map((item, categoryIndex) =>
                    isLearner && !item.enabled
                      ? null
                      : (
                        <Accordion
                          defaultExpanded={appDetail.name !== 'Resource Links'}
                          key={item.uid}
                          sx={{
                            '&:before': {
                              height: 0
                            }
                          }}
                          style={{
                            marginTop: 16,
                            borderRadius: 4
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Typography>{item.name}</Typography>
                              {!isLearner && appDetail.name === 'Resource Links' && (
                                <Switch
                                  checked={item.enabled}
                                  value={item.enabled}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => {
                                    onWebLinkCategoryToggle(
                                      categoryIndex,
                                      e.target.checked
                                    );
                                  }}
                                  color="primary"
                                />
                              )}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            {item.urls[0].image
                              ? renderAccordionImageContent(item, categoryIndex)
                              : renderAccordionTextContent(item, categoryIndex)
                            }
                          </AccordionDetails>
                        </Accordion>
                      )
                  )
                  : null
              }
            </section>
          </Box>
        </div>
      </Box>
    );
  }
};
export default observer(Course);
