import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Typography,
  SearchInput,
  Table,
  Button,
  TextInput
} from '@nucleos/core-ui';

import { QueryKeys } from '../../Lib/query-keys';
import Data from '../../Middleware/Data';
import AuthenticationStore from '../../Stores/Authentication';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import { useFiltersManager } from '../../hooks/useFilters';
import { LoadingAndErrorHandler } from '../../Components/Shared/LoadingErrorHandler';
import { formatDateTime } from '../../Lib/util';
import { GenericNoDataFound } from '../../Components/ErrorStates/GenericNoDataFound';
import { useTableSorting } from '../../hooks/useTableSorting';
import { Icon } from '../../Components/Shared/Icon';

const defaultSorting = {
  column: 'SESSION_START',
  sortOrder: 'DESC'
};

const ApiKeys = {
  learnerName: 'learnerName',
  username: 'username',
  deviceSerialNumber: 'deviceSerialNumber'
};

const ColumnKeys = {
  LEARNER_NAME: 'LEARNER_NAME',
  USERNAME: 'USERNAME',
  SERIAL_NUM: 'SERIAL_NUM',
  SESSION_START: 'SESSION_START',
  SESSION_END: 'SESSION_END'
};

const ActiveSessions = () => {
  const { uid } = AuthenticationStore;

  const filterManager = useFiltersManager({
    defaultFilter: [],
    onFilterChange: () => setPage(1),
    urlKey: 'filters'
  });

  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'active-session'
    });

  const requestData = {
    offset: (page - 1) * recordsPerPage,
    orderBy: columnSorting.sortOrder !== 'NONE' ? columnSorting.column : '',
    order: columnSorting.sortOrder !== 'NONE' ? columnSorting.sortOrder : '',
    q: JSON.stringify({
      learnerName: filterManager.getValue(ApiKeys.learnerName) || '',
      username: filterManager.getValue(ApiKeys.username) || '',
      serialNum: filterManager.getValue(ApiKeys.deviceSerialNumber) || ''
    }),
    limit: recordsPerPage
  };

  const activeSessionsQuery = useQuery(
    QueryKeys.LearnerSessionLookup.listing(requestData),
    () => Data.getLearnerActiveSessions(requestData),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: { rows: [], count: 0 }
    }
  );

  const columns = [
    {
      title: 'Learner Name',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.LEARNER_NAME),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.LEARNER_NAME, sortOrder }),
      render: (item) => <Typography>{item.learnerName}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Full name"
          value={filterManager.getValue(ApiKeys.learnerName) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(ApiKeys.learnerName) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: ApiKeys.learnerName }); }
            filterManager.onFilterApply({
              key: ApiKeys.learnerName,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Username',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.USERNAME),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.USERNAME, sortOrder }),
      render: (item) => <Typography>{item.User.username}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Username"
          value={filterManager.getValue(ApiKeys.username) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(ApiKeys.username) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: ApiKeys.username }); }
            filterManager.onFilterApply({
              key: ApiKeys.username,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Device Serial No.',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.SERIAL_NUM),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.SERIAL_NUM, sortOrder }),
      render: (item) => <Typography>{item.deviceSerialNumber}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Device ID"
          value={filterManager.getValue(ApiKeys.deviceSerialNumber) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(ApiKeys.deviceSerialNumber) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: ApiKeys.deviceSerialNumber }); }
            filterManager.onFilterApply({
              key: ApiKeys.deviceSerialNumber,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Session Start Time',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.SESSION_START),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.SESSION_START, sortOrder }),
      render: (item) => <Typography>{formatDateTime(item.sessionStartAt)}</Typography>
    },
    {
      title: 'Session End Time',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.SESSION_END),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.SESSION_END, sortOrder }),
      render: (item) => (
        new Date(item.sessionStartAt) < new Date(item.sessionEndAt)
          ? <Typography>{formatDateTime(item.sessionEndAt)}</Typography>
          : <Typography color='error'>Indefinite</Typography>
      )
    },
    {
      title: '',
      enableSort: false,
      render: (item) => (
        <Button
          variant="outlined"
          color="error"
          style={{ padding: '6px 8px' }}
          onClick={() => onUnlockDevice(item)}
        >
          <Icon icon="LockOpen" fontSize="small" className="mr-1" />
          <span>Unlock Device</span>
        </Button>
      )
    }
  ];

  const unlockDeviceSchema = yup.object().shape({
    note: yup.string().max(250, 'Number of characters exceeded the limit of 250.').required('Please enter note')
  });

  const unlockDeviceForm = useForm({
    resolver: yupResolver(unlockDeviceSchema),
    defaultValues: { note: '' }
  });

  const unlockDeviceHandler = async ({ note }, user, onCloseModal) => {
    const payload = {
      note,
      userUid: uid,
      userDeviceSessionUid: user.uid
    };
    unlockDeviceMutation.mutate(payload, {
      onSuccess: () => {
        toast.success(`Device successfully unlocked from ${user.learnerName}`);
        unlockDeviceForm.reset({ note: '' });
        activeSessionsQuery.refetch();
      },
      onError: () => {
        toast.error('Something went wrong');
      }
    });
    onCloseModal();
  };

  const unlockDeviceMutation = useMutation((data) =>
    Data.unlockDevice(data).then(GenericErrorDetectorForMutations)
  );

  const onUnlockDevice = (user) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        const closeModalHandler = () => {
          unlockDeviceForm.reset({ note: '' });
          onClose();
        };
        return (
          <div className="nucleos-core">
            <div className="mx-auto my-0 w-2/3">
              <div
                className="flex flex-col justify-start items-center p-10 rounded-md"
                style={{ background: '#ffffff' }}
              >
                <div>
                  <Icon icon="Cancel"
                    className="h-14 w-14"
                    style={{ fill: '#F83232' }}
                  />
                </div>
                <div className="text-2xl mt-6">Are you sure?</div>
                <div className="text-sm mt-3">
                  <span className="opacity-60">You want to unlock the device of the user&nbsp;</span>
                  <span className="underline">{user.learnerName}</span>&nbsp;
                  <span className="opacity-60">? User can log in with another device after that.</span>
                </div>
                <form
                  className="w-full mt-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    unlockDeviceForm.handleSubmit((data) =>
                      unlockDeviceHandler(data, user, onClose),
                    (error) => console.log(error)
                    )(e);
                  }}
                >
                  <div>
                    <Typography>
                      Note&nbsp;<span style={{ color: '#F83232' }}>*</span>
                    </Typography>
                    <Controller
                      name="note"
                      control={unlockDeviceForm.control}
                      render={({ field, fieldState }) => (
                        <TextInput
                          {...field}
                          style={{ resize: 'none' }}
                          placeholder="Add a note..."
                          multiline
                          rows={6}
                          onChange={(_, evt) => field.onChange(evt)}
                          value={field.value}
                          error={!!fieldState.error}
                          helperText={(fieldState.error || {}).message}
                          disableHelperText={!fieldState.error}
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <Typography className="flex justify-end italic">
                    Maximum 250 characters
                  </Typography>
                  <div className="flex items-center justify-center mt-8">
                    <Button
                      style={{ color: '#333333' }}
                      onClick={closeModalHandler}
                      className="mr-2.5"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      style={{ backgroundColor: '#F83232' }}
                      variant="contained"
                      className="ml-2.5"
                    >
                      Unlock Device
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false,
      closeOnEscape: false
    });
  };

  return (
    <div className="nucleos-core">
      <div className="my-5" />
      <LoadingAndErrorHandler
        isLoading={activeSessionsQuery.isLoading}
        isSuccess={activeSessionsQuery.isSuccess}
        isError={activeSessionsQuery.isError}
      >
        <Table
          columns={columns}
          noDataMessage={
            <GenericNoDataFound
              className="mb-5"
              primaryMessage="No Active Sessions"
              secondaryMessage="There are no active sessions at this point of time."
            />
          }
          loading={activeSessionsQuery.isFetching}
          rowsData={activeSessionsQuery.data.rows ? activeSessionsQuery.data.rows : []}
          pagination
          totalRecords={activeSessionsQuery.data.count ? activeSessionsQuery.data.count : 0}
          recordsPerPage={recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
        />
      </LoadingAndErrorHandler>
    </div>
  );
};

export default ActiveSessions;
