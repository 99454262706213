import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BaseUserSearch from './BaseUserSearch';
import DataStore from '../../../Stores/DataStore';
import './UserSearch.scss';

const UserGroupSearch = props => {
  const [searchString, setSearchString] = useState('');
  const [mayDisplaySearchResults, setMayDisplaySearchResults] = useState(false);
  const { searchUser, searchGroup } = DataStore;

  function handleSearchInputChange (event) {
    const value = event.target && event.target.value ? event.target.value.trim() : '';

    if (searchString.length === 0 && value.length === 0) {
      return;
    }

    if (value.length > 0) {
      searchUser(value);
      searchGroup(value);
      setMayDisplaySearchResults(true);
    }
    setSearchString(value);
  }

  function setUser (user) {
    const { onSelectUser, onSelectGroup } = props;
    searchUser(null);
    searchGroup(null);
    setSearchString('');
    user.name ? onSelectGroup(user) : onSelectUser(user);
  }

  function handleBlur () {
    const wait = () =>
      new Promise(resolve => {
        setTimeout(resolve, 400);
      });
    wait().then(() => setMayDisplaySearchResults(false)).catch(() => null);
  }
  const { userSearchResult, groupSearchResult } = DataStore;
  const searchResult = groupSearchResult.concat(userSearchResult);
  const { label, disabled } = props;
  return (
    <BaseUserSearch
      label={label}
      disabled={disabled}
      searchString={searchString}
      handleSearchInputChange={handleSearchInputChange}
      handleBlur={handleBlur}
      userSearchResult={searchResult}
      mayDisplaySearchResults={mayDisplaySearchResults}
      setUser={setUser} />
  );
};

UserGroupSearch.propTypes = {
  label: PropTypes.string,
  onSelectUser: PropTypes.func.isRequired
};

export default observer(UserGroupSearch);
